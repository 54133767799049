@import "../../../common/styles/variables";

.FundItem {
    $radius: 7px;
    $height: auto;
    background-color: $colorPrimary;
    background-size: 100%;
    height: $height;
    border-radius: $radius;
    background-position: center 40%;
    position: relative;
    transition: all 0.25s ease-in-out;
    -webkit-transition: all 0.25s ease-in;
    transition: all 0.25s ease-in;
    height: 70px;
    cursor: pointer;

    .btn { 
        margin-top: 15px; 
    }

    .arrow {
        transition: all 0.25s ease-in-out;
    }

    &.active {
        cursor: default;
        background-size: 105%;
        height: 190px;

        .arrow {
            transform: rotate(90deg);
        }
    }

    &:before {
        position: absolute;
        content: '';
        border-radius: $radius;
        top: 0;
        width: 100%;
        height: 100%;
        //background: linear-gradient(180deg, transparentize($colorSecondary, .4) 25%, transparentize($colorSecondary, .2) 100%)
    }

    .inside {
        position: relative;
        padding: 20px;

        small {
            line-height: 140%;
        }

        * {
            color: white;
        }

        h2 {
            margin: 0;
        }
    }
}

.Feed {
    margin-top: 24px;

    @include respond-below(md) {
        margin-top: 0;
    }
}

.FeedItem {
    padding: 15px 0;
    justify-content: center;
    border-bottom: 1px solid #ececec;

    h5 {
        color: darken($colorText, 20%);
    }

    .feed-value {
        font-size: 1.1em;
        font-weight: bold;
    }
    .feed-status, .feed-date {
        flex: 1;
    }
   
}

.overview-container {
    @include respond-below(md) {
        .container {
            width: 100%;
            padding: 0 !important;
        }
    }
}