////////////////////////////////////////////////////
// Colors
////////////////////////////////////////////////////

// Body Background
$bodyBackground : #EDEDF5;

// Primary Colors
$colorPrimary : #272a2c;
$colorSecondary : #141516;
$colorSuccess: #1DCC70;
$colorDanger : #FF396F;
$colorWarning : #FFB400;
$colorInfo : #05D0A4;
$colorPrimaryLight: #82a7c2;

$colorAccent: #DBBD89;
$colorAccentDark: #96713a;

// Line Colors
$colorLine : #DCDCE9;

// Text Colors
$colorHeading : #27173E;
$colorText : rgb(149, 141, 158);
$colorLight : #A9ABAD;

// Border Radius
$borderRadius: 10px;

// Safearea
$safeBottom : env(safe-area-inset-bottom);
$safeTop : env(safe-area-inset-top);


////////////////////////////////////////////////////
// Dark Mode Colors
////////////////////////////////////////////////////
$darkmode_bodyBackground: #030108;
$darkmode_contentBackground: #161129;
$darkmode_colorHeading: #fff;
$darkmode_colorText: #8f82a5;
$darkmode_colorLight: #69587f;
$darkmode_colorLine: #2d1f3b;


////////////////////////////////////////////////////
// Font Sizes
////////////////////////////////////////////////////
// You can change font family in _font.scss
$fontSizeHeadingXLarge: 32px;
$fontSizeHeadingLarge: 20px;
$fontSizeHeading: 17px;
$fontSize: 15px;
$desktopFontSize: 13px;
$fontSizeSub: 13px;
$fontSizeCaption: 11px;

$bodyLineHeight: 1.6rem;
$bodyLetterSpacing: 0.004em;

$headerHeight: 56px;

//
//  MEDIA QUERIES
//––––––––––––––––––––––––––––––––––––––––––––––––––

// A map of breakpoints.
$breakpoints: (
  xs: 576px,
  sm: 768px,
  md: 992px,
  lg: 1200px
);

//
//  RESPOND ABOVE
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include respond-above(sm) {}
@mixin respond-above($breakpoint) {

  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {

    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    // Write the media query.
    @media (min-width: $breakpoint-value) {
      @content;
    }
  
  // If the breakpoint doesn't exist in the map.
  } @else {

    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}


//
//  RESPOND BELOW
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include respond-below(sm) {}
@mixin respond-below($breakpoint) {

  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {

    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    // Write the media query.
    @media (max-width: ($breakpoint-value - 1)) {
      @content;
    }
  
  // If the breakpoint doesn't exist in the map.
  } @else {

    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}


//
//  RESPOND BETWEEN
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include respond-between(sm, md) {}
@mixin respond-between($lower, $upper) {

  // If both the lower and upper breakpoints exist in the map.
  @if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper) {

    // Get the lower and upper breakpoints.
    $lower-breakpoint: map-get($breakpoints, $lower);
    $upper-breakpoint: map-get($breakpoints, $upper);

    // Write the media query.
    @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
      @content;
    }
  
  // If one or both of the breakpoints don't exist.
  } @else {

    // If lower breakpoint is invalid.
    @if (map-has-key($breakpoints, $lower) == false) {

      // Log a warning.
      @warn 'Your lower breakpoint was invalid: #{$lower}.';
    }

    // If upper breakpoint is invalid.
    @if (map-has-key($breakpoints, $upper) == false) {

      // Log a warning.
      @warn 'Your upper breakpoint was invalid: #{$upper}.';
    }
  }
}