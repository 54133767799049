.card {
  background: #ffffff;
  border-radius: $borderRadius;
  border: none !important;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.09);

  &.full-height-card {
    @include respond-above(sm) {
      height: calc(100vh - (#{$headerHeight} * 2) - 2rem);
    }
  
    overflow-y: hidden;
    display: flex;
  }

  &.full-mobile {
    border-radius: 0;

    @include respond-below(sm) {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
    
    @include respond-above(sm) {
      border-radius: $borderRadius;
    }
  }
  
  &.click {
    &:hover { 
      background: darken(white, 5%);
    }
  }

  .card-body {
    padding: 16px;
    box-sizing: border-box;

    &.border-primary {
      border-left: 4px solid $colorPrimary !important;
    }

    &.border-accent {
      border-left: 4px solid $colorAccent;
    }

    &.border-primary-light {
      border-left: 4px solid $colorPrimaryLight;
    }

  }
  .card-title {
    color: $colorHeading;
    font-size: $fontSizeHeading;
    font-weight: $medium;
    text-align: center;

    @include respond-above(sm) {
      text-align: left;
    }
  }
  .card-subtitle {
    color: $colorHeading;
    font-weight: $regular;
    font-size: $fontSizeSub;
  }
  .listview {
    > li:first-child {
      .item {
        border-radius: $borderRadius $borderRadius 0 0;
      }
    }
    > li:last-child {
      .item {
        border-radius: 0 0 $borderRadius $borderRadius;
      }
    }
  }
  .card-header {
    background: transparent;
    color: $colorHeading;
    font-weight: $medium;
    padding: 12px 16px;
    border-bottom: 1px solid $colorLine;
  }
  .card-footer {
    background: transparent;
    border-top: 1px solid $colorLine;
    color: $colorText;
    padding: 12px 16px;
  }
  .card-img-top{
    border-radius: $borderRadius $borderRadius 0 0;
  }
  .overlay-img{
    border-radius: $borderRadius;
  }
  .card-img-overlay {
    background: rgba(0, 0, 0, 0.5);
    border-radius: $borderRadius;
    .card-title {
      color: #fff;
    }
    .card-text {
      color: rgba(255, 255, 255, 0.7);
    }
  }
  &.bg-primary,
  &.bg-secondary,
  &.bg-danger,
  &.bg-success,
  &.bg-warning,
  &.bg-info,
  &.bg-dark {
    border: 0;
    .card-title {
      color: #fff;
    }
    .card-text {
      color: rgba(255, 255, 255, 0.7);
    }
    .card-header {
      color: #fff;
      border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    }
  }
  &.bg-light {
    background: #fff;
  }
}
