@import "../styles/variables";
@import "../styles/font";

.simple-list {
  @include respond-above(sm) {
    overflow-y: scroll;
    position: absolute;
    height: 90%;
    left: 16px;
    right: 16px;
  }

  .simple-list-item {
    cursor: pointer;
    padding: 16px 10px;
    border-bottom: 1px solid darken(white, 5%);
    border-radius: 10px;

    h4 {
      margin-bottom: 0;
    }

    .send-file {
      flex: 1;
    }

    .actions {
      text-align: right;
    }

    &:hover {
      background: darken(white, 5%);
    }

    .forward-icon {
      margin-left: 20px;
      svg {
        color: $colorLight;
      }
    }

    .item-value {
      font-size: 1em;
      color: $colorHeading;
      font-weight: 500;
    }
  }
}

.CopyContent {
  svg {
    cursor: pointer;
    margin-left: 8px;
  }

  &.before {
    svg {
      margin-left: 0px;
      margin-right: 8px;
    }
  }
}

.profile-strenght {
  cursor: pointer;

  &:hover {
    background-color: darken($colorPrimary, 2%) !important;
  }

  &.bg-success {
    &:hover {
      background-color: darken($colorSuccess, 2%) !important;
    }
  }

  ul {
    padding-left: 0;
    padding-top: 10px;

    svg {
      margin-right: 5px;
    }
  }

  p {
    line-height: 120%;
    margin-top: 10px;
    margin-bottom: 0;

    strong {
      font-weight: 700;
    }
  }
}

.progress-circle {
  circle {
    transform: rotate(-90deg);
  }

  .animate {
    animation: offsettozero 1s ease-in-out forwards reverse;
  }
}

@keyframes offsettozero {
  to {
    stroke-dashoffset: 100;
  }
}

.file-preview {
  border: 1px solid $colorLight;
  border-radius: 4px;
  margin: 10px 0;
  padding: 10px;

  .file-text {
    min-width: 0;
    flex: 1;
  }

  strong {
    color: $colorHeading;
  }

  &:hover { 
    background: darken(#fff, 3%);
    cursor: pointer;
  }
}

.last-incomes {
  display: flex;
  justify-content: space-around;

  h3 {
    margin-bottom: 0;
  }

  .incomes-percent {
    align-items: center;
    display: flex;
    flex-direction: column;
	position: relative;

    div {
      display: flex;
      align-items: center;
    }

	small {
		top: 95%;
		background: $colorLight;
		line-height: 100%;
		border-radius: 3px;
		padding: 3px 7px;
		color: white;
	}
  }

  @include respond-below(sm) {
    flex-direction: row;
    flex-wrap: wrap;

    .incomes-percent {
      flex-basis: 50%;
      margin-top: 20px;

      &:nth-child(1),
      &:nth-child(2) {
        margin-top: 0;
      }
    }
  }
}

.appBottomMenu {
  min-height: 56px;
  position: fixed;
  z-index: 999;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid $colorLine;
  padding-left: 4px;
  padding-right: 4px;
  padding-bottom: env(safe-area-inset-bottom);

  &.no-border {
    border: 0 !important;
    box-shadow: 0 !important;
  }
  .item {
    font-size: 9px;
    letter-spacing: 0;
    text-align: center;
    width: 100%;
    height: 56px;
    line-height: 1.2em;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    &:before {
      content: "";
      display: block;
      height: 2px;
      border-radius: 0 0 10px 10px;
      background: transparent;
      position: absolute;
      left: 4px;
      right: 4px;
      top: 0;
    }
    .col {
      width: 100%;
      padding: 0 4px;
      text-align: center;
    }
    .icon,
    svg {
      display: inline-flex;
      margin: 1px auto 3px auto;
      font-size: 24px;
      line-height: 1em;
      color: $colorHeading;
      transition: 0.1s all;
      display: block;
      margin-top: 1px;
      margin-bottom: 3px;
    }
    .action-button {
      display: inline-flex;
      width: 50px;
      height: 50px;
      margin-left: -5px;
      margin-right: -5px;
      align-items: center;
      justify-content: center;
      border-radius: 200px;
      background: $colorPrimary;
      &.large {
        width: 60px;
        height: 60px;
        margin-top: -20px;
        margin-left: -10px;
        margin-right: -10px;
      }
      .icon,
      svg {
        color: #fff !important;
        margin: 0 !important;
        line-height: 0 !important;
      }
    }
    strong {
      margin-top: 4px;
      display: block;
      color: $colorHeading;
      font-weight: $regular;
      transition: 0.1s all;
    }
    &:active {
      opacity: 0.8;
    }
    &.active {
      &:before {
        background: $colorPrimary;
      }
      .icon,
      svg,
      strong {
        color: $colorPrimary !important;
        font-weight: $medium;
      }
    }
    &:hover {
      .icon,
      svg,
      strong {
        color: $colorHeading;
      }
    }
  }
  &.text-light {
    color: #fff;
    .item {
      color: #fff;
      opacity: 0.7;
      .icon,
      svg,
      strong {
        color: #fff;
      }
      &.active {
        opacity: 1;
        .icon,
        svg,
        strong {
          color: #fff !important;
        }
      }
    }
  }
  &.bg-primary,
  &.bg-secondary,
  &.bg-success,
  &.bg-warning,
  &.bg-danger,
  &.bg-info,
  &.bg-light,
  &.bg-dark {
    border: 0;
    .item {
      &:before {
        display: none;
      }
    }
  }
}

.clear-bottom-menu {
  @include respond-above(sm) {
    display: none;
  }
}

.updateNotifier {
  position: fixed;
  z-index: 9999;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 15px;
  box-sizing: border-box;
  cursor: pointer;
  transition: all 0.25s ease-in-out;

  svg {
    margin-right: 10px;
  }

  &:hover {
    background-color: darken($colorSuccess, 4%) !important;
  }
}

.container-header {
  margin-bottom: 56px;
}

.ios-add-to-home {
  
  .modal-dialog {
    width: 100% !important;
    max-width: 100% !important;
    position: absolute !important;
    bottom: 0;
  }

  .modal-header {
    border-bottom-color: transparent !important;
  }
  .modal-content {
    width: 100% !important;
    position: relative;
    margin-bottom: 10px;
    z-index: 1;
    box-shadow: 0 10px 20px -4px rgba(0, 0, 0, 0.2) !important;
    &:before {
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-left: 14px solid transparent;
      border-right: 14px solid transparent;
      border-top: 10px solid #fff;
      position: absolute;
      bottom: -7px;
      z-index: 10;
      left: 50%;
      margin-left: -16px;
    }
    .action-sheet-content {
      font-size: $fontSizeSub;
      color: $colorHeading;
      line-height: 1.5em;
      h4 {
        font-size: $fontSize;
        margin-bottom: 10px;
      }
      i.bi,
      i.icon,
      svg {
        font-size: 22px;
        margin-bottom: -4px;
      }
    }
  }
}


@keyframes logoText {
  0% {
     opacity: 0;
     transform: translateX(-20px);
  }
  100% {
     opacity: 1;
     transform: translateX(0);
  }
}   

@keyframes logoSVG {
  0% {
     opacity: 0;
     transform: translateY(-8px) translateX(20px);
  }
  50% {
     opacity: 1;
     transform: translateY(0) translateX(20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}   

.root-logo {
  display: flex;
  align-items: center;

  &.column {
    flex-direction: column;

    .logo-text {
      transform: scale(1.3);

      .sbaraini {
        margin-left: 10px;
        margin-top: 0;
      }
    }
  }

  .logo {
    fill: black;
    stroke: transparent;
    fill-rule: evenodd;
    clip-rule: evenodd;
    stroke-width: 0;
    stroke-miterlimit: 10;
  }
  
  .logo-svg {
    &.animate {
      opacity: 0;
      animation-name: logoSVG;
      animation-duration: 1s;
      animation-fill-mode: forwards; 
    }
  }
  
  .logo-text {
    font-family: 'Exo 2', sans-serif;
    font-size: 24px;
    letter-spacing: 0.4rem;
    display: flex;
    flex-direction: column;
    font-weight: 800;


    &.animate {
      opacity: 0;
      animation-name: logoText;
      animation-duration: 1s;
      animation-delay: .5s;
      animation-fill-mode: forwards; 
    }

    .sbaraini {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 1px;
      margin-left: -4px;
      animation-delay: .7s;

      .text {
        position: absolute;
        text-align: center;
        font-size: .43em !important;
        font-family: 'Libre Baskerville', serif;
        color: white;
        letter-spacing: 3.20px;
        margin-top: 1.15px;
        margin-left: 3px;
        font-weight: 300;
      }

      svg {
        width: 100px;
      }
    }
  }
}


.offline-indicator {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: white;
  z-index: 10000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  span {
    margin-top: 10px;
  }
}

.preloading {
  $size: 22.6%;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  flex-direction: column;
  .animation {
    opacity: 0;
    animation-name: enterLogo;
    animation-duration: 1s;
    animation-delay: .5s;
    animation-fill-mode: forwards; 

  }

  &.exit {
    animation-name: exitLogo;
    animation-duration: .5s;
    animation-fill-mode: forwards; 
  }
}

@keyframes exitLogo {
  0% {
    padding-top: 0;
  }
  40% {
    padding-top: 23px;
    opacity: 1;
  }
  80% {
    padding-top: 23px;
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}   


@keyframes enterLogo {
  0% {
     opacity: 0;
     transform: scale(.1);
  }
  40% {
     opacity: 1;
     transform: scale(1.2);
  }
  60% {
    opacity: 1;
    transform: scale(1) translateY(0);
    margin-bottom: 0px;
  }
  100% {
    transform: scale(1) translateY(-23px);
    opacity: 1;
  }
}   

.file-input {
  border: 1px solid $colorText;
  border-style: dashed;
  border-radius: 10px;
  padding: 14px 10px;
  cursor: pointer;

  &.uploaded {
    cursor: unset;
  }

  .document-sending {
    width: 1.35rem;
    margin-right: 10px;
    height: 1.35rem;
    border-width: 0.20em;
  }

  input {
    display: none;
  }

  .text {
    padding: 8px 0;

    svg {
      margin-right: 10px;
    }
  }
}

.fund-switcher {
  
  .fund-switcher__selected {
    color: white;
    padding: 17px 16px;
    box-sizing: border-box;
    cursor: pointer;
    line-height: 75%;
    font-size: 1.35em;
    height: 100%;
    transition: all 0.25s ease-in;

    .icon-arrow {
      font-size: 0.8em;
      &.open {
        transform: rotate(90deg);
      }
    }

    small {
      font-size: 0.6em;
      margin: 0;
      text-align: right;
      margin-right: 10px;
    }

    .sbaraini-logo {
      margin-right: 10px;
    }

  }

  .fund-switcher__list {
    background-color: #f0eeee;
    width: calc(100%);
    color: #8B8B8C;
    padding: 16px;
  }

  .fund-switcher__icon {
    background: $colorPrimary;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 5px;
    padding: 2px;
    transition: all 0.1s ease-in;

    .funds-logo {
      stroke: white;
    }
  }

  .fund-switcher__list-item {
    padding: 10px 8px;
    border-radius: 7px;
    cursor: pointer;
    color: $colorSecondary;
    line-height: 105%;
    font-size: 1.1em;
    transition: all 0.25s ease-in;

    &:hover {
      background-color: darken(#f3f3f3, 0.2);

      .fund-switcher__icon {
        background-color: $colorAccent;
      }
    }
  }
}

.sbaraini-logo {
  .icon {
    .st0{fill:#133751;}
    .st1{fill:#8B8B8C;}
    .st2{fill:#D9A556;}
    .st3{fill:url(#SVGID_1_);}
    .st4{fill:#FFFFFF;}

    &.white {
      .st0{fill:#FFFFFF;}
      .st1{fill:#FFFFFF;}
      .st2{fill:#FFFFFF;}
      .st3{fill: white;}
      .st4{fill:#FFFFFF;}
    }
  }

  .text {
    margin-left: 10px;
    .st0{fill:#133751;}
    .st1{fill:#8B8B8C;}
    .st2{fill:#FFFFFF;}

    &.white {
      .st0{fill:#FFFFFF;}
      .st1{fill:#FFFFFF;}
      .st2{fill:#FFFFFF;}
    }
  }
}

.smart-scroll::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

.smart-scroll::-webkit-scrollbar
{
	width: 3px;
	background-color: #F5F5F5;
}

.smart-scroll::-webkit-scrollbar-thumb
{
	background-color: #3c3c3c6e;
  border-radius: 50px;
}

.funds-logo {
  stroke: white;
}
