@import "../../../common/styles/variables";

.deposit-form {
  p {
    margin-bottom: 0;
  }
}

.deposit-risk-indicator {
  width: 90%;
  margin: 0 auto;

  @include respond-above(sm) {
    width: 65%;
  }
}

.deposit-info-card {
  
  .card-title {
    display: none;

    @include respond-above(sm) {
      display: block;
    }
  }

  .listview {
    margin-top: 0 !important;
  }

}



.deposit-title {
  text-align: center;

  @include respond-above(sm) {
    text-align: left;
  }
}

.deposit-status-text {
  svg {
    margin-left: 10px;
  }
}

.icon-wrapper {
  text-align: center;
  margin-top: 20px;

  .iconbox {
    width: 64px;
    height: 64px;
    border-radius: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: $colorPrimary;

    &.success {
      background: $colorSuccess;
    }

    font-size: 26px;
    svg {
      color: #FFF;
    }
  }
}

.depositInformation {
  .listview {
    span {
      font-size: 0.88em;
      text-align: right;
      display: flex;
      align-items: center;
    }

    svg {
      color: $colorPrimary;
      cursor: pointer;
      margin-left: 8px;
    }
  }
}

.receipt-preview {
  .frame {
    display: flex; 
    align-items: center;
    overflow: hidden;
    width: 100%;
    border: 1px solid #d8d8d8;
    border-radius: 10px;
  }

}

.term {
  max-height: 75vh;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 25px;

  b {
    font-weight: bold;;
  }
}

.invitation-modal {
  
  .highlight {
    margin-top: 30px;
    border: 1px solid $colorPrimary;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.Latam {
  .qr-code-wrapper {
    border: 3px solid $colorPrimary;
    border-radius: 7px;
    padding: 13px;
  }

  .qr-code-text {
    @include respond-below(sm) {
      flex-direction: column;

      >div {
        margin-bottom: 30px;
      }
    }
  }
}