@import "variables";

// RTL layout changes for arabic language

html[dir="rtl"] {
  .header {
    flex-direction: row-reverse;

    .left {
      left: unset;
      right: 10px;
    }

    .right {
      right: unset;
      left: 10px;
    }
  }

  .form-group .label {
    text-align: right;
  }
  
  .form-group .clear-input {
    left: 0;
    right: unset;
  }
  
  .document-uploader .text svg {
    margin-right: unset;
    margin-left: 10px;
  }

  .image-listview > li a.item {
    padding-right: 16px;
    padding-left: 36px;
  }

  .image-listview > li .item .icon-box {
    margin-right: unset;
    margin-left: 16px;
  }

  .btn-group > .btn-group:not(:first-child) > .btn, .btn-group > .btn:nth-child(n+3), .btn-group > :not(.btn-check) + .btn {
    border-top-left-radius: unset;
    border-bottom-left-radius: unset;
  }

  .btn-group > .btn-group:not(:last-child) > .btn, .btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
    border-top-left-radius: unset;
    border-bottom-left-radius: unset;
  }

  .btn-group > .btn-group:not(:first-child) > .btn, .btn-group > .btn:nth-child(n+3), .btn-group > :not(.btn-check) + .btn {
    border-radius: 0px;
  }

  .dropdown-toggle:after {
    margin-left: unset;
    margin-right: 7px;
  }

  .btn svg {
    margin-right: unset;
    margin-left: 7px;
  }

  .deposit-list .deposit-list-item .forward-icon {
    margin-left: 0;
    margin-right: 20px;
  }

  .deposit-list .deposit-list-item .forward-icon svg {
    transform: scaleX(-1);
  }

  .depositInformation .listview svg {
    margin-left: 0;
    margin-right: 8px;
  }

  .mru-10 {
    margin-left: 10px;
  }

  .graph-legend .graph-legend-item div {
    margin-right: 0;
    margin-left: 10px;
  }

  .CopyContent.before svg {
    margin-right: 0;
    margin-left: 8px;
  }

  .CopyContent svg {
    margin-left: 0;
    margin-right: 8px;
  }

  .container-header {

    @include respond-above(sm) {
      margin-left: 0;
      margin-right: 300px;
    }
  }

  .sidebar {

    margin-right: -300px;

    @include respond-above(sm) {
      margin-right: -1px;
    }

    &.open {
      margin-right: 0;
    }
  }

  .dropdown .dropdown-menu.dropdown-menu-right, .dropup .dropdown-menu.dropdown-menu-right {
    left: 0;
    right: unset;
  }

  .header .left .dropdown-menu-right svg, .header .right .dropdown-menu-right svg {
    margin-right: unset;
    margin-left: 20px;
  }
}