@import "../styles/variables";
@import "../styles/font";

.sidebar-overlay {
  background: rgba(0,0,0, 0.5);
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: none;
  z-index: 10000;

  &.open {
    display: block;
  }

  @include respond-above(sm) {
    &.open {
      display: none;
    }
  }
}

.sidebar {
  svg {
    color: white !important;
  }

  overflow-y: auto;
  position: fixed;
  width: 300px;
  background: white;
  height: 100vh;
  margin-left: -300px;
  z-index: 10001;
  transition: margin 0.2s ease-in;

  .item {

    padding-top: 9px !important;
    padding-bottom: 9px !important;

    &.active {
      .icon-box {
        background: darken($colorPrimary, 5%) !important;
      }
    }

    .in {
      transition: all 0.15s ease-in-out;
    }

    &:hover { 
      .in {
        margin-left: 2px;
      }
    }
  }

  &.open {
    margin-left: 0;
  }

  @include respond-above(sm) {
    margin-left: 0;
    top: unset;
    z-index: 999;
    border-right: 1px solid rgb(231, 231, 231);
  }

  .profileBox {
    padding: 0 16px;
    display: flex;
    align-items: center;

    @include respond-above(sm) {
      display: none;
    }

    .image-wrapper {
      margin-right: 16px;
    }
    .in {
      line-height: 1.4em;
      padding-right: 25px;
      strong {
        display: block;
        font-weight: $medium;
        color: $colorHeading;
      }
      .text-muted {
        font-size: 14px;
        color: $colorLight !important;
      }
    }
  }
  
  .sidebar-balance{
    padding: 6px 16px;
    background: $colorPrimary;

    
    @include respond-above(sm) {
      padding-top: 56px;
    }

    .in {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: white !important;

      svg {
        color: white;
        fill: white;
      }
    }

    @include respond-above(sm) {
      background: $colorSecondary;
    }

    .listview-title{
      padding-right: 0;
      padding-left: 0;
      color: #FFF;
      opacity: .6;
    }
    .amount{
      font-weight: $bold;
      letter-spacing: -0.01em;
      line-height: 1em;
      font-size: 21px;
      font-weight: $fontSizeHeadingXLarge;
      color: #FFF;
      margin-bottom: 6px;
    }
  }
  .action-group{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0px 16px 10px 16px;
    background: $colorPrimary;

    @include respond-above(sm) {
      background: $colorSecondary;
    }

    svg {
      color: white;
    }

    .action-button{
      padding: 10px 2px;
      display: inline-flex;
      cursor: pointer;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: $fontSizeCaption;
      line-height: 1em;
      color: rgba(255,255,255,.7);
      .iconbox{
        background: rgba(#000, .3);
        width: 38px;
        height: 38px;
        margin: 0 auto 8px auto;
        border-radius: 100%;
        font-size: 18px;
        color: #FFF;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &:last-child{
        border-right: 0;
      }
    }
  }
  #sidebarPanel{
    .modal-dialog{
      position: fixed;
      left: 0;
      top: 0;
      bottom: 0;
    }
    .modal-body{
      margin-bottom: 34px;
      padding-bottom: env(safe-area-inset-bottom) !important;
      width: 300px;
    }
  }
  .panelbox-left,
  .panelbox-right{
    .listview{
      > li{
        padding: 10px 16px;
      }
    }
    .link-listview{
      > li{
        padding: 0;
        a{
          padding: 10px 36px 10px 16px;
        }
      }
    }
    .image-listview{
      > li{
        padding: 0;
        .item{
          padding: 10px 16px;
        }
        a.item{
          padding-right: 36px;
        }
      }
    }
  }
  .sidebar-close{
    position: absolute;
    right: 10px;
  }

  .copyright {
    line-height: 140%;
    margin-top: 35px;
    text-align: right !important;
    bottom: 10px;
    right: 0;
  }
}