@import "../styles/variables";

.container-header {
  padding-top: 56px;
  transition: margin ease-in 0.2s;

  @include respond-above(sm) {
    margin-left: 300px;
  }
}

.container-content {
  padding: 0;
  
  @include respond-above(sm) {
    padding: 1rem !important;
  }
}