@import "./variables";

html, body {
  position: relative;
  overflow-x: hidden;
  min-height: 100% !important;
  height: 100%;
}

#root {

  min-height: 100% !important;
  height: 100%;
  @include respond-below(md) {
  }
}

body{
  font-family: $fontFamily;
  font-size: $fontSize;
  line-height: $bodyLineHeight;
  letter-spacing: $bodyLetterSpacing;
  color: $colorText;
  background: $bodyBackground;
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  @include respond-above(sm) {
    font-size: $desktopFontSize;
  }
}

.body-on-modal {
  touch-action: none;
  -webkit-overflow-scrolling: none;
  overflow: hidden;
  overscroll-behavior: none;
}

a{
  transition: .2s all;
  color: $colorPrimary;
  outline: 0 !important;
  text-decoration: none;
  &:hover,
  &:active,
  &:focus{
    outline: 0 !important;
    color: $colorPrimary;
    text-decoration: none;
  }
}

.bg-first {
  background-color: $colorPrimary;
}

.bg-liquidez {
  background-color: $colorAccent;
}

.bg-performance {
  background-color: $colorPrimaryLight;
}

.bg-primary-light {
  background-color: lighten($colorPrimary, 10)
}

.bg-primary-lightness {
  background-color: lighten($colorPrimary, 18)
}

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.pointer {
  cursor: pointer;
}

.mt-0 {
  margin-top: 0 !important;
}

.mru-10 {
  margin-right: 10px;
}
// hide all the scroll bars

// ::-webkit-scrollbar {
//   -webkit-appearance: none;
//   width: 7px;
// }

// ::-webkit-scrollbar-thumb {
//   border-radius: 4px;
//   background-color: rgba(0,0,0,.5);
//   -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
// }